html {
    scroll-padding-top: 140px !important; /* height of sticky header */
}

html, body {
    height: 100%;
    color-scheme: dark;
}

body {
    display: flex;
    flex: 1;
    padding: 0;
    margin: 0;
}

#root {
    display: flex;
    flex: 1;
}

h1 {
    font-size: 47px;
    line-height: 60px;
    margin: 0;
    font-weight: 600;
}

h2 {
    font-size: 44px;
    line-height: 48px;
    margin: 0;
    font-weight: 600;
}

h3 {
    font-size: 32px;
    line-height: 36px;
    margin: 0;
    font-weight: 600;
}

h4 {
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    font-weight: 600;
}

h5 {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    font-weight: 600;
}

p {
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
}

.ant-btn {
    font-weight: 300;
    box-shadow: none;
}

.swiper-slide img {
    width: 37rem;
    height: 42rem;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {

}

.rc-slider-handle {
    opacity: 1 !important;
}

.rc-slider-handle-dragging {
    box-shadow: none !important;
}

.rc-slider-mark-text {
    top: 15px;
}

.rc-slider-dot {
    border: 0 !important;
    border-radius: 0 !important;
    width: 2px !important;
    height: 5px !important;
    top: 10px;
    background-color: #47474B !important;
}


.horizontal-menu-anchor {
    margin-block-start: 0px;
}

.horizontal-menu-anchor .ant-anchor-ink {
    display: none;
}

.horizontal-menu-anchor.ant-anchor-wrapper-horizontal::before {
    border: 0;
}

.horizontal-menu-anchor.ant-anchor-wrapper {
    padding: 0;
}

.horizontal-menu-anchor .ant-anchor-link-title {
    color: #A0AABA !important;
}

.horizontal-menu-anchor .ant-anchor-link-title-active {
    color: #FFFFFF !important;
}

.ant-anchor-wrapper .ant-anchor .ant-anchor-link {
    padding-inline: 20px !important;
}
